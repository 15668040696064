@media only screen and (max-width: 767px) {
  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }
  .aspect__ratio {
    pointer-events: none;
    z-index: -2;
    margin-top: 46.5%;
  }
  .subtitle {
    display: none;
    max-width: 80%;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.125rem;
    row-gap: 1.875rem;
    width: 100%;
  }
  .button {
    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
    height: 2.625rem;
    width: 100%;
  }
  .animation {
    margin-top: 2.25rem;
    position: relative;
  }

  .development {
    bottom: 5rem;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    row-gap: 0.5rem;
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .progress-container {
    align-items: center;
    background-color: var(--color-illustrations-gray);
    display: flex;
    height: 0.063rem;
    justify-content: center;
    margin-top: 0.75rem;
    position: relative;
    width: 100%;
    --width-multiplier: 0.88;
    --width-offset: 0.025rem;
  }
  .progress-bar {
    background: var(--gradient-main-circle);
    border: 0.188rem solid var(--color-text-accent);
    border-bottom: none;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    border-width: 0.14rem;
    left: -0.2rem;
    opacity: 0.3;
    position: absolute;
    transform: translateY(-50%) translateX(0.6rem);
    transition: width 0.2s;
  }
  .progress-bar-dummy {
    margin-top: 50%;
  }
  .progress-circle {
    background: var(--gradient-main-circle-disabled);
    border: 0.063rem solid var(--color-illustrations-gray);
    border-bottom: none;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    border-width: 0.039rem;
    left: -0.2rem;
    opacity: 0.5;
    position: absolute;
    transform: translateY(-50%) translateX(0.6rem);
    transition: border 0.3s;
    z-index: -1;
  }
  .progress-circle-last {
    border: 0.063rem solid transparent;
    border-bottom: none;
  }
  .progress-circle-last[data-reached="true"] {
    border: 0.063rem solid transparent;
    border-bottom: none;
  }
  .progress-circle[data-reached="true"] {
    border: 0.063rem solid transparent;
    border-bottom: none;
  }
  .checkpoint {
    background-color: var(--color-illustrations-black-primary);
    border-radius: 50%;
    height: 0.75rem;
    position: absolute;
    width: 0.75rem;
    z-index: 100;
  }
  .checkpoint-first {
    background-color: var(--color-illustrations-black-primary);
    border: 0.19rem solid var(--color-brand-white);
  }
  .checkpoint[data-reached="true"]::before {
    background-color: var(--color-text-accent);
  }
  .checkpoint-first::before {
    background-color: var(--color-illustrations-black-primary) !important;
  }
  .checkpoint-first[data-reached="true"]::before {
    background-color: var(--color-illustrations-black-primary) !important;
  }
  .checkpoint-first[data-reached="true"] {
    background-color: var(--color-text-accent);
    border-color: var(--color-text-accent);
  }
  .checkpoint::before {
    background-color: var(--color-text-disabeld);
    border-radius: 50%;
    content: "";
    height: 0.5rem;
    width: 0.5rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .checkpoint::after {
    background-color: transparent;
    border-radius: 50%;
    content: "";
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
  }
  .checkpoint-label {
    color: var(--color-text-disabeld);
    display: none;
    font-size: 1rem;
    line-height: 1rem;
    margin-left: 0.5rem;
    position: absolute;
    top: 1.5rem;
    width: 8rem;
  }
  .checkpoint[data-reached="true"] {
    color: var(--color-text-main);
  }
  .checkpoint-label:hover {
    cursor: default;
  }
  .navigation {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 8.5rem;
    margin-bottom: 0.75rem;
  }
  .navigation-button {
    --button-background-color: var(--button-slider-default-background);
    --button-height: 2.5rem;
    --button-width: 2.5rem;
    border-radius: 0.5rem;
    --icon-height: 0.7rem;
    --icon-width: 0.7rem;
    appearance: none;
    touch-action: manipulation;
  }
  .navigation-button:disabled {
    --button-color: var(--button-slider-disabled-background);
  }
  .headline {
    text-align: center;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .development-header {
    --font-size: 1.25rem;
    --line-height: 1.625rem;
  }

  .not_mobile {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }
  .aspect__ratio {
    pointer-events: none;
    z-index: -2;
    margin-top: 43.5%;
  }
  .navigation {
    column-gap: 1rem;
    display: none;
    display: flex;
    margin-top: 4.9rem;
    margin-bottom: 1.5rem;
  }
  .subtitle {
    display: inline-block;
    max-width: 80%;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2.5rem;
    row-gap: 1.875rem;
    width: 100%;
  }
  .button {
    font-size: 1.125rem;
    height: 3.375rem;
    line-height: 1.25rem;
    width: 17.625rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
  .animation {
    margin-top: 2.25rem;
    position: relative;
  }
  .development {
    bottom: 19.75rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0rem;
    row-gap: 0.5rem;
    width: 17.625rem;
  }
  .progress-container {
    align-items: center;
    background-color: var(--color-illustrations-gray);
    display: flex;
    height: 0.1rem;
    justify-content: center;
    margin-top: 0.75rem;
    position: relative;
    width: 100%;
    margin-left: -0.1rem;
    --width-multiplier: 0.975;
    --width-offset: 0.17rem;
  }
  .progress-bar {
    background: var(--gradient-main-circle);
    border: 0.188rem solid var(--color-text-accent);
    border-bottom: none;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    transform: translateY(-50%) translateX(0.6rem);
    transition: width 0.2s;
  }
  .progress-bar-dummy {
    margin-top: 48%;
  }
  .progress-circle {
    background: var(--gradient-main-circle-disabled);
    border: 0.063rem solid var(--color-illustrations-gray);
    border-bottom: none;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    transform: translateY(-50%) translateX(0.6rem);
    transition: border 0.3s;
    z-index: -1;
  }
  .progress-circle-last {
    border: 0.063rem solid transparent;
    border-bottom: none;
  }
  .progress-circle-last[data-reached="true"] {
    border: 0.063rem solid transparent;
    border-bottom: none;
  }
  .progress-circle[data-reached="true"] {
    border: 0.063rem solid transparent;
    border-bottom: none;
  }
  .checkpoint {
    background-color: var(--color-illustrations-black-primary);
    border-radius: 50%;
    height: 1.35rem;
    position: absolute;
    width: 1.35rem;
    z-index: 100;
  }
  .checkpoint-first {
    background-color: var(--color-brand-white);
  }
  .checkpoint[data-reached="true"]::before {
    background-color: var(--color-text-accent);
  }
  .checkpoint-first::before {
    background-color: var(--color-illustrations-black-primary) !important;
  }
  .checkpoint-first[data-reached="true"]::before {
    background-color: var(--color-illustrations-black-primary);
  }
  .checkpoint-first[data-reached="true"] {
    background-color: var(--color-text-accent);
  }
  .checkpoint::before {
    background-color: var(--color-text-disabeld);
    border-radius: 50%;
    content: "";
    height: 0.875rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.875rem;
  }
  .checkpoint::after {
    background-color: transparent;
    border-radius: 50%;
    content: "";
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
  }
  .checkpoint-label {
    color: var(--color-text-disabeld);
    font-size: 1rem;
    line-height: 1rem;
    margin-left: 0.25rem;
    position: absolute;
    top: 1.25rem;
    width: 8rem;
  }
  .checkpoint[data-reached="true"] {
    color: var(--color-text-main);
  }
  .checkpoint-label:hover {
    cursor: default;
  }
  .navigation-button {
    --button-background-color: var(--button-slider-default-background);
    --button-height: 3rem;
    --button-width: 3rem;
    border-radius: 0.5rem;
    --icon-height: 0.7rem;
    --icon-width: 0.7rem;
    appearance: none;
    touch-action: manipulation;
  }
  .navigation-button:disabled {
    --button-color: var(--button-slider-disabled-background);
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }
  .aspect__ratio {
    pointer-events: none;
    z-index: -2;
    margin-top: 28.25%;
  }
  .subtitle {
    display: inline-block;
    max-width: 50%;
  }
  .cta {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
  }
  .button {
    font-size: 1.125rem;
    height: 3.375rem;
    line-height: 1.25rem;
    width: 17.625rem;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }
  .animation {
    margin-top: 2.25rem;
    position: relative;
  }
  .development {
    bottom: 5rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    row-gap: 0.5rem;
    width: 17.625rem;
  }
  .progress-container {
    align-items: center;
    background-color: var(--color-illustrations-gray);
    display: flex;
    height: 0.188rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 0.75rem;
    position: relative;
    width: 100%;
    margin-left: -0.4rem;
    --width-multiplier: 1.325;
    --width-offset: 0.17rem;
  }
  .progress-bar {
    background: var(--gradient-main-circle);
    border: 0.188rem solid var(--color-text-accent);
    border-bottom: none;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    transform: translateY(-50%) translateX(0.6rem);
    transition: width 0.2s;
  }
  .progress-bar-dummy {
    margin-top: 46.7%;
  }
  .progress-circle {
    background: var(--gradient-main-circle-disabled);
    border: 0.063rem solid var(--color-illustrations-gray);
    border-bottom: none;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    left: 0;
    opacity: 0.7;
    position: absolute;
    transform: translateY(-50%) translateX(0.6rem);
    transition: border 0.3s;
  }
  .progress-circle-last {
    border: 0.06rem solid transparent;
    border-bottom: none;
  }
  .progress-circle-last[data-reached="true"] {
    border: 0.06rem solid transparent;
    border-bottom: none;
  }
  .progress-circle[data-reached="true"] {
    border: 0.06rem solid transparent;
    border-bottom: none;
  }
  .checkpoint {
    background-color: var(--color-illustrations-black-primary);
    border-radius: 100%;
    height: 1.35rem;
    position: absolute;
    width: 1.35rem;
    z-index: 100;
  }
  .checkpoint-first {
    background-color: var(--color-brand-white);
  }
  .checkpoint[data-reached="true"]::before {
    background-color: var(--color-text-accent);
  }
  .checkpoint-first::before {
    background-color: var(--color-illustrations-black-primary) !important;
  }
  .checkpoint-first[data-reached="true"]::before {
    background-color: var(--color-illustrations-black-primary);
  }
  .checkpoint-first[data-reached="true"] {
    background-color: var(--color-text-accent);
  }
  .checkpoint::before {
    background-color: var(--color-text-disabeld);
    border-radius: 100%;
    content: "";
    height: 0.875rem;
    left: 49%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.875rem;
  }
  .checkpoint::after {
    background-color: transparent;
    border-radius: 100%;
    content: "";
    height: 3rem;
    left: 49%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
  }
  .checkpoint-label {
    color: var(--color-text-disabeld);
    font-size: 1rem;
    line-height: 1rem;
    margin-left: 0.75rem;
    position: absolute;
    top: 1.2rem;
  }
  .checkpoint[data-reached="true"] {
    color: var(--color-text-main);
  }
  .checkpoint-label:hover {
    cursor: default;
  }
  .navigation {
    display: none;
  }
}
