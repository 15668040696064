@media only screen and (max-width: 767px) {
  .DialogOverlay {
    background-color: var(--black-a9);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;
  }

  .DialogContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: flex-start;

    background-color: var(--color-text-main);
    border-radius: 0;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding-top: 3.5rem;
    padding-bottom: 1.25rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;
  }

  .DialogSuccessContentContainer {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    transform: translateY(-10%);
  }
  .DialogSuccessDescription {
    --font-size: 0.938rem;
    --line-height: 1.375rem;
    margin-top: 0.675rem;
  }

  .DialogContent:focus {
    outline: none;
  }

  .DialogTitle {
    text-align: center;
    margin-top: auto;
    color: var(--color-illustrations-black-bg) !important;
  }

  .DialogDescription {
    text-align: center;
    color: var(--color-text-secondary-2) !important;
    font-size: 0.938rem;
    line-height: 1.375rem;
    margin-top: 0.5rem;
  }

  .SubmitBtn {
    height: 2.625rem;
    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
    margin-top: 0.5rem;
  }
  .SubmitBtnSuccess {
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 100%;
    max-width: 20.25rem;
  }
  .CloseBtn {
    position: absolute;
    --button-background-color: var(--icon-button-default-background);
    color: var(--color-button-tertiary-bg) !important;
    --button-width: 2.625rem;
    --button-height: 2.625rem;
    --icon-height: 0.7rem;
    --icon-width: 0.7rem;
    top: 0.938rem;
    right: 1.125rem;
  }

  .files_text {
  }
  .files_container {
    display: flex;
    align-items: center;
    color: var(--color-text-secondary-2);
    border: 0.063rem solid var(--color-stroke);
    border-radius: 0.375rem;
    font-size: 0.938rem;
    line-height: 1.063rem;
    height: 3.313rem;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  /* .files_container:is(:disabled, :read-only) {
  opacity: 0.5;
} */
  .files_container:focus-within {
    outline: 0.063rem solid var(--color-text-secondary-2);
    outline-offset: 0.125rem;
  }
  .files_description {
    color: var(--color-text-secondary-2);
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.023rem;
  }
  .files {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
  .files_btn {
    color: var(--color-text-accent);
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
  }
  .form {
    --background: transparent;
    --border: white;
    --check-color: white;
    --foreground: transparent;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    row-gap: 1rem;
  }
  .terms {
    cursor: pointer;
    align-items: center;
    column-gap: 0.75rem;
    display: flex;
    font-size: 0.875rem;
    justify-content: flex-start;
    line-height: 1rem;
    margin-top: 0.5rem;
    width: 100%;
    color: var(--color-text-secondary-2);
    --border: var(--color-text-secondary-2);
    --check-color: var(--color-text-secondary-2);
  }
  .input {
    background-color: var(--input-default-backround);
    border: 0.063rem solid var(--color-stroke);
    border-radius: 0.375rem;
    color: var(--color-illustrations-black-bg);
    font-size: 0.938rem;
    line-height: 1.063rem;
    height: 3.313rem;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }
  .input:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .input:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .input:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary-2);
    outline-offset: 0.125rem;
  }
  .input::placeholder {
    color: var(--color-text-secondary-2);
  }
  .textarea {
    background-color: var(--input-default-backround);
    border: 0.063rem solid var(--color-stroke);
    border-radius: 0.375rem;
    color: var(--color-illustrations-black-bg);
    font-size: 1rem;
    height: 7.313rem;
    line-height: 1rem;
    padding: 1.25rem 1rem;
    padding-bottom: 1rem;
    resize: none;
    margin-bottom: -0.2rem;
    width: 100%;
  }
  .textarea:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .textarea:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .textarea:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary-2);
    outline-offset: 0.125rem;
  }
  .textarea::placeholder {
    color: var(--color-text-secondary-2);
  }
  .form__contact {
    column-gap: 1.25rem;
    display: flex;
  }
  .label {
    pointer-events: none;
    color: var(--color-text-secondary);
    font-weight: 400;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .form__item {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
  }

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
@media only screen and (min-width: 768px) {
  .DialogOverlay {
    background: var(--dialog-overlay-background);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;
  }

  .DialogContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: var(--color-text-main);
    border-radius: 1.875rem;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 38.75rem;
    min-height: 43.688rem;
    padding-top: 4.5rem;
    padding-bottom: 3.125rem;
    padding-left: 5.75rem;
    padding-right: 5.75rem;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;
  }

  .DialogContent:focus {
    outline: none;
  }

  .DialogSuccessContentContainer {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transform: translateY(-10%);
  }
  .DialogTitle {
    text-align: center;
    color: var(--color-illustrations-black-bg) !important;
  }

  .DialogTitleSuccess {
    margin-top: 1rem;
  }

  .DialogDescription {
    text-align: center;
    color: var(--color-text-secondary-2) !important;
    --font-size: 0.938rem;
    --line-height: 1.375rem;
    margin-top: 0.75rem;
  }

  /* .DialogSuccessDescription {
    margin-top: 0.25rem;
  } */

  .SubmitBtn {
    width: 100%;
    height: 3.375rem;
    margin-top: 0.75rem;
  }

  .SubmitBtnSuccess {
    margin-top: 1.125rem;
  }

  .CloseBtn {
    position: absolute;
    --button-background-color: var(--icon-button-default-background);
    color: var(--color-button-tertiary-bg) !important;
    --button-width: 3.125rem;
    --button-height: 3.125rem;
    --icon-height: 0.7rem;
    --icon-width: 0.7rem;
    top: 0.938rem;
    right: 2.5rem;
  }

  .files_text {
  }
  .files_container {
    display: flex;
    align-items: center;
    color: var(--color-text-secondary-2);
    border: 0.063rem solid var(--color-stroke);
    border-radius: 0.375rem;
    font-size: 1rem;
    height: 3.5rem;
    line-height: 1rem;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    width: 100%;
    cursor: pointer;
  }
  /* .files_container:is(:disabled, :read-only) {
  opacity: 0.5;
} */
  .files_container:focus-within {
    outline: 0.063rem solid var(--color-text-secondary-2);
    outline-offset: 0.125rem;
  }
  .files_description {
    color: var(--color-text-secondary-2);
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .files {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
  .files_btn {
    color: var(--color-text-accent);
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
  }
  .form {
    --background: transparent;
    --border: white;
    --check-color: white;
    --foreground: transparent;
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
  }
  .terms {
    cursor: pointer;
    align-items: center;
    column-gap: 0.75rem;
    display: flex;
    font-size: 0.875rem;
    justify-content: flex-start;
    line-height: 1rem;
    margin-top: 1.25rem;
    width: 100%;
    color: var(--color-text-secondary-2);
    --border: var(--color-text-secondary-2);
    --check-color: var(--color-text-secondary-2);
  }
  .input {
    background-color: var(--input-default-backround);
    border: 0.063rem solid var(--color-stroke);
    border-radius: 0.375rem;
    color: var(--color-illustrations-black-bg);
    font-size: 1rem;
    height: 3.5rem;
    line-height: 1rem;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }
  .input:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .input:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary-2);
    outline-offset: 0.125rem;
  }
  .input::placeholder {
    color: var(--color-text-secondary-2);
  }
  .textarea {
    background-color: var(--input-default-backround);
    border: 0.063rem solid var(--color-stroke);
    border-radius: 0.375rem;
    color: var(--color-illustrations-black-bg);
    font-size: 1rem;
    height: 7.313rem;
    line-height: 1rem;
    padding: 1.25rem 1rem;
    padding-bottom: 1rem;
    resize: none;
    width: 100%;
  }
  .textarea:invalid {
    color: var(--color-text-error);
  }
  .textarea:invalid + span {
    color: var(--color-text-error);
  }
  .textarea:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .textarea:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary-2);
    outline-offset: 0.125rem;
  }
  .textarea::placeholder {
    color: var(--color-text-secondary-2);
  }
  .form__contact {
    column-gap: 1.25rem;
    display: flex;
  }
  .label {
    pointer-events: none;
    color: var(--color-text-secondary);
    font-weight: 400;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .form__item {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
  }

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.errorMessage {
  display: none;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
.files_container.error {
  color: var(--color-text-error) !important;
}
.fileList {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.fileList button {
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: space-between;
  --icon-width: 0.7rem;
  --icon-height: 0.7rem;
  padding: 0.5rem;
  background-color: var(--file-background);
  outline: none;
  border: none;
  border-radius: 0.375rem;
  color: var(--color-text-secondary-2);
}
.fileList button svg {
  color: var(--color-illustrations-dark-gray);
}
.fileList button:focus-visible {
  outline: 0.063rem solid var(--color-text-secondary-2);
  outline-offset: 0.125rem;
}
.error + .errorMessage {
  display: block;
  color: var(--color-text-error);
}
.terms.error {
  color: var(--color-text-error);
  --border: var(--color-text-error);
  --check-color: var(--color-text-error);
}

.SubmitBtn:focus-visible {
  outline: 0.063rem solid var(--color-text-secondary-2);
  outline-offset: 0.125rem;
}
.CloseBtn:focus-visible {
  outline: 0.063rem solid var(--color-text-secondary-2);
  outline-offset: 0.125rem;
}
