.FormDescription {
    padding-top: 0.275rem;
    font-size: 0.875rem; 
    color: hsl(var(--muted-foreground));
  }
  
  .FormMessage {
     font-size: 0.875rem; 
     font-weight: 500; 
     transform: translateY(4px);
     color: hsl(var(--destructive-foreground));
  }
  
  .FormLabel {
    color: hsl(var(--foreground));
  }