.download__link {
  color: white !important;
}

.article__image__scale_down {
  /* object-fit: scale-down; */
  min-height: fit-content;
  border-radius: 0;
}

.article__image__full_height {
  position: relative !important;
}

.article a {
  --link-color: white;
  text-decoration: underline !important;
}

.main__cta_text a {
  --link-color: white;
  text-decoration: underline !important;
}

.article__image__full__width {
  /* width: 100% !important; */
  /* object-fit: cover !important; */
  /* margin: 0; */
}

.article__image__main {
  max-height: 20.75rem;
}

.main__image__container {
  position: relative;
  overflow: hidden;
  /* object-fit: cover;
  width: 100%;
  min-width: 100%; */
  /* height: 28.75rem; */
  height: auto;
  max-height: 28.75rem;
  border-radius: 1.25rem;
  margin-top: 3.7rem;
}

.main__image__container .article__image__container {
  margin: 0;
}
.article__image__container__two__images {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 2rem;
}

.article__image {
  position: relative !important;
  /* object-fit: scale-down; */
  margin: auto;
  border-radius: 1.25rem;
  height: fit-content;
}
.article__image__container__two__images__col {
  flex-direction: column;
}
/* .article__image__container__carousel .article__image {
  width: 100% !important;
} */

.article__image__wrapper {
  position: relative;
  border-radius: 1.25rem;
  height: fit-content;
}
.article__image__container__two__images .article__image__wrapper {
  flex-basis: 50%;
}

.article__section__text__image .article__image__wrapper {
  align-items: center;
  margin-right: 0rem;
  margin-top: 0.25rem;
}
.article__image__container:first-child {
  margin-top: 0 !important;
}
@media only screen and (max-width: 767px) {
  .main__cta {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .main__image__container {
    /* height: 20.75rem; */
    max-height: 20.75rem;
  }
  .main__cta_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
  }
  .download__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.75rem;
    row-gap: 0.5rem;
  }
  .download__button {
    height: 2.625rem;
    width: 100%;
    font-size: 0.938rem;
    line-height: 1.375rem;
  }
  .download__button__description {
    font-size: 0.938rem;
    line-height: 1.375rem;
  }

  .image__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    height: 18.375rem;
    min-height: 18.375rem;
    border-radius: 1.25rem;
    margin-top: 3rem;
  }
  .main__image {
    object-fit: cover;
  }

  .article {
    margin-top: 2.75rem;
    background-color: var(--article-background-color);
    border-radius: 1.25rem;
    padding: 2rem 1rem;
    padding-bottom: 3.75rem;
    overflow: hidden;
  }
  .article__section {
    margin: 3.25rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .article__section:first-child {
    margin-top: 0;
  }
  .article__section:last-child {
    margin-bottom: 0;
  }
  .article__blockquote {
    position: relative;
    margin-top: 3.5rem;
    margin-bottom: -0.5rem;
    padding: 0.5rem 1.25rem;
    padding-bottom: 1.325rem;
    row-gap: 0.5rem;
  }
  .article__blockquote::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.375rem;
    background: var(--gradient-text-quote-line);
  }

  .article__big__paragraph {
    margin-top: -1rem;
    --font-size: 1.125rem;
    --line-height: 1.625rem;
  }
  .article__headline {
    margin-bottom: 0.5rem;
  }

  h4.article__headline {
    margin-top: 0.35rem;
    margin-bottom: 0.25rem;
  }
  h3.article__headline {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h4.article__headline + p {
    margin-top: 0.625rem;
  }
  h4.article__headline + ul {
    margin-top: 0.75rem;
  }
  .article__ul__two__col__headline {
    --font-size: 1.5rem;
    --line-height: 1.875rem;
  }
  .article__ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    margin-left: 0.025rem;
    margin-top: 0.325rem;
    margin-bottom: 0.15rem;
  }
  .article__ul__item {
    position: relative;
    font-size: 0.938rem;
    line-height: 1.375rem;
    padding-left: 1rem;
  }
  .article__ul__item::before {
    content: "";
    position: absolute;
    /* top: 50%;
    transform: translateY(-50%); */
    top: 0;
    transform: translateY(115%);
    left: 0;
    width: 0.325rem;
    height: 0.325rem;
    border-radius: 100%;
    background-color: var(--color-text-accent);
  }
  .article__ul__big .article__ul__item {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .article__ul__two__col {
    margin-top: 1rem;
    row-gap: 0.85rem;
  }
  .article__ul__two__col > .article__ul__item {
    margin-top: -0.1rem;
  }
  .article__ul__two__col > .article__ul__item:nth-child(2n) {
    justify-self: end;
    margin-right: 0.5rem;
  }
  .article__image__container {
    margin-top: 2.75rem;
    /* margin-bottom: 2.75rem; */
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .article__image__container:not(:last-child) {
    margin-bottom: 2.75rem;
  }
  .article__image__container + p {
    margin-top: 0.5rem;
  }

  .article__image__description {
    /* margin-top: 1.5rem; */
    margin-top: 0.35rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .article__image__container__carousel {
    backface-visibility: hidden;
    touch-action: pan-y pinch-zoom;
    margin: 0;
    display: flex;
  }
  .article__image__container__carousel .article__image {
    width: 100% !important;
  }

  .article__image__container__carousel__two__image {
    margin: 0 4rem;
    column-gap: 2rem;
  }
  .article__image__carousel {
    overflow: hidden;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .article__image {
    min-width: auto;
  }
  .article__image__container__carousel .article__image__wrapper {
    width: 100%;
    min-width: 100%;
    margin-left: 1.75rem;
    margin-right: -0.5rem;
    padding: 0 1rem;
  }
  .article__image__container__carousel__two__image .article__image__wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .article__image__container__carousel__counter {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1rem;
  }
  .article__image__container__carousel__counter__button {
    --button-background-color: transparent;
    width: 2.5rem !important;
    height: 2.5rem !important;
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .article__image__container__carousel__counter__text {
    text-align: center;
    width: 6rem;
  }

  .article__image__container__two__images {
    display: flex;
    flex-direction: column;
    column-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .article__image__container__two__images + .article__image__description {
    margin-top: 1.5rem;
  }

  .article__cta {
    margin-top: 3.875rem;
    width: 100%;
    min-height: 9.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: var(--gradient-pink-purple-default);
    border-radius: 1rem;
    padding: 1.875rem;

    --font-size: 1.25rem;
    --line-height: 1.5rem;
  }
  .article__cta + * {
    margin-top: 3.75rem;
  }
  .article__cta__button {
    width: 100%;
    height: 2.625rem;
  }

  .article__section + .article__section__text__image {
    /* margin-top: 3.75rem; */
  }

  .article__section__text__image {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .article__section__text__image + .article__section {
    /* margin-top: 3.75rem; */
  }
  .article__section__text__image .article__image__wrapper {
    /* float: left;
    display: flex; */
    /* align-items: center; */
    /* margin-right: 0rem; */
    /* width: 100%;
    min-width: 100%; */
    /* height: 21.875rem; */
    /* min-height: 21.875rem; */
    margin-top: 0.25rem;
  }
  .article__section__text__image__headline {
    margin-bottom: 1.25rem;
    --font-size: 1.5rem;
    --line-height: 1.875rem;
  }
  .article__section__text__image__description {
    --font-size: 1.125rem;
    --line-height: 1.625rem;
  }
  .article__section__text__image__left .article__image__wrapper {
    float: right;
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .article__section__text__image + .article__section > h2.article__headline {
    margin-top: -0.75rem;
  }

  .article__ol {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    margin-top: 2.25rem;
    margin-bottom: 0.25rem;
  }
  .article__ol__description {
    --font-size: 1.125rem;
    --line-height: 1.625rem;
  }
  .article__ol + .article__image__container {
    margin-top: 1.5rem;
    margin-bottom: 0.74rem;
  }
  .article__ol__item {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    row-gap: 0.5rem;

    padding: 1.075rem 0;
    border-bottom: 0.063rem solid var(--color-illustrations-dark-gray);
  }
  .article__ol__item:first-child {
    padding-top: 0;
  }
  .article__ol__item:last-child {
    border-bottom: none;
  }
  .article__ol__text {
    display: flex;
    align-items: flex-end;
    margin-top: 0.5rem;
  }

  .article__section__results {
    margin-top: 5.25rem;
    /* max-width: 18.25rem; */
  }
  .article__results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0rem;
    row-gap: 2.5rem;
    column-gap: 2.5rem;
  }
  .article__results__section {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
  }
  .article__results__button {
    letter-spacing: 0.01rem;
    margin-top: 2.525rem;
    margin-left: 0;
    width: 17.625rem;
    height: 2.625rem;
  }

  .article__section__share {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-self: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 7.375rem;

    background: var(--gradient-pink-purple-form);
    border-radius: 1.25rem;
    padding: 1.375rem 1.125rem;

    margin-top: 3.75rem;
  }
  .article__section__share__text {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  .article__section__share__icon {
    background-color: var(--color-brand-white) !important;
    color: var(--color-button-tertiary-bg) !important;

    margin-left: 1.375rem;
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
  .article__section__share__icon:first-child {
    margin-left: 0rem;
  }

  .similar__projects {
    display: none;
    margin-top: 6.25rem;
  }

  .cases__cards {
    margin-top: 2.5rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3.75rem;
  }
  .card {
    background: transparent;
    border-radius: 1rem;
    height: 18.75rem;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    /* display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 1.25rem;
    margin-left: 1rem;
    margin-top: -0.25rem; */
  }
  .card-hover__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1.75rem;
  }
  .card-hover__text-small {
    font-size: 1.5rem;
    line-height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__text-big {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.25rem;
    line-height: 3.875rem;
  }
  .card-hover__button {
    bottom: 1.25rem;
    position: absolute;
    right: 1.25rem;

    --button-background-color: var(--icon-button-default-background);
    --button-height: 3.125rem;
    --button-width: 3.125rem;
    --button-icon-height: 1rem;
    --button-icon-width: 1rem;
  }
  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.5rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }
  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .main__cta {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .main__cta_text {
    width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 2.25rem;
  }

  .download__container {
    width: 17.625rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    row-gap: 0.5rem;
  }
  .download__button {
    height: 3.375rem;
    width: 100%;
  }
  .download__button__description {
    font-size: 0.938rem;
    line-height: 1.375rem;
  }

  .image__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    height: 28.75rem;
    min-height: 28.75rem;
    border-radius: 1.25rem;
    margin-top: 3rem;
  }
  .main__image {
    object-fit: cover;
  }

  .article {
    margin-top: 2.75rem;
    background-color: var(--article-background-color);
    border-radius: 1.25rem;
    padding: 3.125rem 3.75rem;
    padding-bottom: 3.75rem;
  }
  .article__section {
    margin: 3.125rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .article__section:first-child {
    margin-top: 0;
  }
  .article__section:last-child {
    margin-bottom: 0;
  }
  .article__blockquote {
    position: relative;
    padding: 1.25rem 1.875rem;
    row-gap: 1rem;
  }
  .article__blockquote::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.375rem;
    background: var(--gradient-text-quote-line);
  }
  .article__headline {
    margin-bottom: 0.5rem;
  }

  h4.article__headline {
    margin-top: 0.35rem;
    margin-bottom: 0.25rem;
  }
  h3.article__headline {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h4.article__headline + p {
    margin-top: 0.625rem;
  }
  h4.article__headline + ul {
    margin-top: 0.75rem;
  }
  .article__ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    margin-left: 0.125rem;
    margin-top: 0.1rem;
    margin-bottom: 0.15rem;
  }
  .article__ul__item {
    position: relative;
    font-size: 1.125rem;
    line-height: 1.625rem;
    padding-left: 1rem;
  }
  .article__ul__item::before {
    content: "";
    position: absolute;
    /* top: 50%;
    transform: translateY(-50%); */
    top: 0;
    transform: translateY(115%);
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: var(--color-text-accent);
  }
  .article__ul__two__col__headline {
    --font-size: 1.5rem;
    --line-height: 1.875rem;
  }
  .article__ul__two__col {
    margin-top: 1rem;
    row-gap: 0.85rem;
  }
  .article__ul__two__col > .article__ul__item {
    margin-top: -0.1rem;
  }
  .article__ul__two__col > .article__ul__item:nth-child(2n) {
    justify-self: end;
    margin-right: 0.5rem;
  }
  .article__image__container {
    margin-top: 2.75rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  /* .article__image__container:not(:last-child) {
    margin-bottom: 2.75rem;
  } */
  .article__image__container {
    margin-bottom: 2.75rem;
  }
  .article__image__container + p {
    margin-top: 0.5rem;
  }
  .article__image__description {
    /* margin-top: 1.5rem; */
    margin-top: 0.35rem;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .article__image__container__carousel {
    backface-visibility: hidden;
    touch-action: pan-y pinch-zoom;
    margin: 0;
    display: flex;
  }
  .article__image__container__carousel__two__image {
    margin: 0 4rem;
    column-gap: 2rem;
  }
  .article__image__carousel {
    overflow: hidden;
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }
  .article__image__container__carousel .article__image__wrapper {
    margin-left: 1.75rem;
    margin-right: -0.5rem;
    /* width: 35.5rem; */
    /* max-height: 21.875rem; */
  }
  .article__image__container__carousel__two__image .article__image__wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .article__image__container__carousel__counter {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1rem;
  }
  .article__image__container__carousel__counter__button {
    --button-background-color: transparent;
    width: 3rem !important;
    height: 3rem !important;
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .article__image__container__carousel__counter__text {
    text-align: center;
    width: 6rem;
  }
  .article__image__container__carousel .article__image__wrapper {
    width: 80%;
    min-width: 80%;
    margin-left: 1.75rem;
    margin-right: -0.5rem;
    padding: 0 1rem;
  }
  .article__image__container__carousel .article__image {
    width: 100% !important;
  }
  .article__image__container__two__images {
    display: flex;
    column-gap: 1.25rem;
  }
  .article__image__container__two__images .article__image__wrapper {
    /* width: 100%;
    min-width: 17.188rem;
    min-height: 21.875rem; */
    /* height: 21.875rem; */
  }
  .article__image__container__two__images + .article__image__description {
    margin-top: 1.5rem;
  }

  .article__cta {
    margin-top: 3.875rem;
    width: 100%;
    min-height: 9.625rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--gradient-pink-purple-default);
    border-radius: 1rem;
    padding: 3.125rem 3.75rem;
  }
  .article__cta + * {
    margin-top: 3.75rem;
  }
  .article__cta__button {
    width: 10.875rem;
    height: 3.375rem;
  }

  .article__section + .article__section__text__image {
    margin-top: 3.75rem;
  }

  .article__section__text__image {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .article__section__text__image + .article__section {
    margin-top: 3.75rem;
  }
  .article__section__text__image .article__image__wrapper {
    /* float: left; */
    /* display: flex; */
    /* align-items: center; */
    margin-right: 0rem;
    width: 100%;
    min-width: 100%;
    /* height: 21.875rem; */
    /* min-height: 21.875rem; */
    margin-top: 0.25rem;
  }
  .article__section__text__image__headline {
    margin-bottom: 1.25rem;
  }
  .article__section__text__image__left .article__image__wrapper {
    float: right;
    display: flex;
    align-items: center;
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .article__section__text__image + .article__section > h2.article__headline {
    margin-top: -0.75rem;
  }

  .article__ol {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0rem;
    margin-top: 2.25rem;
  }
  .article__ol + .article__image__container {
    margin-top: 1.5rem;
    margin-bottom: 0.74rem;
  }
  .article__ol__item {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2.25fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;

    padding: 1.075rem 0;
    border-bottom: 0.063rem solid var(--color-illustrations-dark-gray);
  }
  .article__ol__item:first-child {
    padding-top: 0;
  }
  .article__ol__item:last-child {
    border-bottom: none;
  }
  .article__ol__text {
    display: flex;
    align-items: flex-end;
    margin-top: 0.5rem;
  }

  .article__section__results {
    margin-top: 5.25rem;
    /* max-width: 25rem; */
  }
  .article__results {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.75rem;
    column-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .article__results__section {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
  }
  .article__results__button {
    letter-spacing: 0.01rem;
    margin-top: 2.525rem;
    margin-left: 0;
    width: 17.625rem;
    height: 3.375rem;
  }

  .article__section__share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    width: 100%;
    height: 5rem;

    background: var(--gradient-pink-purple-form);
    border-radius: 1.25rem;
    padding: 1.375rem 1.125rem;

    margin-top: 3.75rem;
  }
  .article__section__share__text {
    margin-right: auto;
  }
  .article__section__share__icon {
    background-color: var(--color-brand-white) !important;
    color: var(--color-button-tertiary-bg) !important;

    margin-left: 1.25rem;
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
  .article__section__share__icon:first-child {
    margin-left: 0rem;
  }

  .similar__projects {
    display: none;
    margin-top: 6.25rem;
  }

  .cases__cards {
    margin-top: 2.5rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3.75rem;
  }
  .card {
    background: transparent;
    border-radius: 1rem;
    height: 18.75rem;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    /* display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 1.25rem;
    margin-left: 1rem;
    margin-top: -0.25rem; */
  }
  .card-hover__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1.75rem;
  }
  .card-hover__text-small {
    font-size: 1.5rem;
    line-height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__text-big {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.25rem;
    line-height: 3.875rem;
  }
  .card-hover__button {
    bottom: 1.25rem;
    position: absolute;
    right: 1.25rem;

    --button-background-color: var(--icon-button-default-background);
    --button-height: 3.125rem;
    --button-width: 3.125rem;
    --button-icon-height: 1rem;
    --button-icon-width: 1rem;
  }
  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.5rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }
  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
}
@media only screen and (min-width: 1440px) {
  .main__container {
  }
  .main__cta {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .main__cta_text {
    width: 49%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }
  .main__headline {
  }
  .main__description {
  }

  .download__container {
    width: 17.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }
  .download__button {
    height: 3.375rem;
    width: 100%;
  }
  .download__button__description {
    font-size: 0.938rem;
    line-height: 1.375rem;
  }

  .image__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    height: 28.75rem;
    min-height: 28.75rem;
    border-radius: 1.25rem;
    margin-top: 3rem;
  }
  .main__image {
    object-fit: cover;
  }

  .article {
    margin-top: 2.75rem;
    background-color: var(--article-background-color);
    border-radius: 1.25rem;
    padding: 3.125rem 12.063rem;
  }
  .article__section {
    margin: 3.125rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .article__section:first-child {
    margin-top: 0;
  }
  .article__section:last-child {
    margin-bottom: 0;
  }
  .article__blockquote {
    position: relative;
    padding: 1.25rem 1.875rem;
  }

  .article__blockquote::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.375rem;
    background: var(--gradient-text-quote-line);
  }
  .article__headline {
    margin-bottom: 0.5rem;
  }

  h4.article__headline {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  h3.article__headline {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h4.article__headline + p {
    margin-top: 0.625rem;
  }

  .article__ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    margin-left: 0.3125rem;
    margin-top: 0.225rem;
    margin-bottom: 0.15rem;
  }
  .article__ul__item {
    position: relative;
    font-size: 1.125rem;
    line-height: 1.625rem;
    padding-left: 1rem;
  }
  .article__ul__item::before {
    content: "";
    position: absolute;
    /* top: 50%;
    transform: translateY(-50%); */
    top: 0;
    transform: translateY(115%);
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: var(--color-text-accent);
  }
  .article__ul__two__col {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0rem;
    grid-row-gap: 0.75rem;
    margin-top: 1.25rem;
  }
  .article__ul__two__col > .article__ul__item {
    margin-top: -0.1rem;
  }
  .article__ul__two__col > .article__ul__item:nth-child(2n) {
    justify-self: end;
    margin-right: 0.5rem;
  }
  .article__image__container {
    margin-top: 2.75rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .article__image__container:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  .article__image__container {
    margin-bottom: 2.75rem;
  }
  .article__image__container + p {
    margin-top: 0.5rem;
  }

  .article__image__description {
    margin-top: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .article__image__container__carousel {
    backface-visibility: hidden;
    touch-action: pan-y pinch-zoom;
    margin: 0;
    display: flex;
  }
  .article__image__carousel {
    overflow: hidden;
    margin-left: -12.063rem;
    margin-right: -12.063rem;
  }
  .article__image__container__carousel__two__image {
    margin: 0 4rem;
    column-gap: 2rem;
  }
  .article__image__container__carousel .article__image__wrapper {
    width: 70%;
    min-width: 70%;
    margin-left: 2rem;
    margin-right: -0.5rem;
    /* padding: 0 1rem; */
  }
  .article__image__container__carousel__two__image .article__image__wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .article__image__container__carousel__counter {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1rem;
  }
  .article__image__container__carousel__counter__button {
    --button-background-color: transparent;
    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .article__image__container__carousel__counter__text {
    text-align: center;
    width: 6rem;
  }

  .article__image__container__two__images {
    display: flex;
    column-gap: 1.25rem;
    justify-content: space-between;
  }
  .article__image__container__two__images .article__image__wrapper {
    /* width: 22rem;
    min-width: 22rem;
    min-height: 21.875rem;
    height: 21.875rem; */
  }
  .article__image__container__two__images + .article__image__description {
    margin-top: 1.5rem;
  }

  .article__cta {
    margin-top: 3.875rem;
    width: 100%;
    min-height: 9.625rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--gradient-pink-purple-default);
    border-radius: 1rem;
    padding: 3.125rem 3.75rem;
  }
  .article__cta + * {
    margin-top: 3.75rem;
  }
  .article__cta__button {
    width: 10.875rem;
    height: 3.375rem;
  }

  .article__section + .article__section__text__image {
    margin-top: 3.75rem;
  }

  .article__section__text__image {
    display: block;
    overflow: hidden;
  }
  .article__section__text__image + .article__section {
    margin-top: 3.75rem;
  }
  .article__section__text__image .article__image__wrapper {
    float: left;
    display: flex;
    align-items: center;
    margin-right: 1.25rem;
    width: 24.125rem;
    min-width: 24.125rem;
    height: 21.875rem;
    min-height: 21.875rem;
  }
  .article__section__text__image__headline {
    margin-bottom: 2rem;
  }
  .article__section__text__image__left .article__image__wrapper {
    float: right;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 1.25rem;
  }
  .article__section__text__image + .article__section > h2.article__headline {
    margin-top: -0.75rem;
  }

  .article__ol {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 0rem;
    margin-top: 2.25rem;
  }
  .article__ol + .article__image__container {
    margin-top: 1.5rem;
  }
  .article__ol__item {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3.125fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;

    padding: 1.25rem 0;
    border-bottom: 0.063rem solid var(--color-illustrations-dark-gray);
  }
  .article__ol__item:first-child {
    padding-top: 0;
  }
  .article__ol__item:last-child {
    border-bottom: none;
  }
  .article__ol__text {
    display: flex;
    align-items: flex-end;
    margin-top: 0.5rem;
  }

  .article__section__results {
    margin-top: 5.75rem;
  }
  .article__results {
    display: flex;
    margin-top: 0.75rem;
    column-gap: 2.5rem;
    row-gap: 2.5rem;
  }
  .article__results__section {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
  }
  .article__results__button {
    letter-spacing: 0.01rem;
    margin-top: 0.5rem;
    margin-left: auto;
    width: 17.625rem;
    height: 3.375rem;
  }

  .article__section__share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    width: 100%;
    height: 5rem;

    background: var(--gradient-pink-purple-form);
    border-radius: 1.25rem;
    padding: 1.375rem 1.125rem;

    margin-top: 3.75rem;
  }
  .article__section__share__text {
    margin-right: auto;
  }
  .article__section__share__icon {
    background-color: var(--color-brand-white) !important;
    color: var(--color-button-tertiary-bg) !important;

    margin-left: 1.25rem;
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
  .article__section__share__icon:first-child {
    margin-left: 0rem;
  }

  .similar__projects {
    margin-top: 6.25rem;
  }

  .cases__cards {
    margin-top: 2.5rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3.75rem;
  }
  .card {
    background: transparent;
    border-radius: 1rem;
    height: 18.75rem;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    /* display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 1.25rem;
    margin-left: 1rem;
    margin-top: -0.25rem; */
  }
  .card-hover__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1.75rem;
  }
  .card-hover__text-small {
    font-size: 1.5rem;
    line-height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__text-big {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.25rem;
    line-height: 3.875rem;
  }
  .card-hover__button {
    bottom: 1.25rem;
    position: absolute;
    right: 1.25rem;

    --button-background-color: var(--icon-button-default-background);
    --button-height: 3.125rem;
    --button-width: 3.125rem;
    --button-icon-height: 1rem;
    --button-icon-width: 1rem;
  }
  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.5rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }
  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
}
