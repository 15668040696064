@media only screen and (max-width: 767px) {
  .main {
    margin-top: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    overflow-x: hidden;
  }
  .breadcrumps {
    margin-top: 1.75rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .contact__container {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
    row-gap: 3.125rem;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 1.75rem;
  }
  .address {
    font-style: normal;
    display: flex;
    flex-direction: column;
    max-width: 85%;
    row-gap: 1rem;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
  .list {
    display: flex;
    list-style-type: none;
  }
  .list__social {
    column-gap: 1rem;
  }
  .map {
    min-width: 20.25rem;
    width: 100%;
    min-height: 25rem;
    height: 25rem;
    border-radius: 1.25rem;
    overflow: hidden;
  }
  .contact__form {
    margin-top: 6.25rem;
    margin-bottom: 1.5rem;
  }
  .contact__form form > button {
    height: 3.25rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1439px) {
  .main {
    margin-top: 1.25rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    overflow-x: hidden;
  }
  .breadcrumps {
    margin-top: 3.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .contact__container {
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 1.75rem;
  }
  .address {
    font-style: normal;
    display: flex;
    flex-direction: column;
    max-width: 85%;
    row-gap: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
  .list {
    display: flex;
    list-style-type: none;
  }
  .list__social {
    column-gap: 0.625rem;
  }
  .map {
    min-width: 24.813rem;
    max-width: 44.375rem;
    width: 100%;
    min-height: 25rem;
    height: 25rem;
    border-radius: 1.25rem;
    overflow: hidden;
  }
  .contact__form {
    margin-top: 6.25rem;
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    margin-top: 1rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
    overflow-x: hidden;
  }
  .breadcrumps {
    margin-top: 3.5rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
  }
  .contact__container {
    display: flex;
    margin-top: 3.125rem;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 1.875rem;
  }
  .address {
    font-style: normal;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
  .list {
    display: flex;
    list-style-type: none;
  }
  .list__social {
    column-gap: 0.625rem;
  }
  .map {
    min-width: 44.375rem;
    width: 44.375rem;
    min-height: 25rem;
    height: 25rem;
    border-radius: 1.25rem;
    overflow: hidden;
  }
  .contact__form {
    margin-top: 6.25rem;
  }
  .contact__form h2 {
    margin-top: 1rem;
  }
  .contact__form h2 + p {
    margin-top: 2.25rem;
  }
}
