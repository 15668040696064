@media only screen and (max-width: 767px) {
  .container {
    margin-top: 6.125rem;
  }
  .card {
    background: var(--gradient-pink-purple-default);
    border-radius: 0.75rem;
    overflow: hidden;
    padding: 1.875rem 1.375rem;
    position: relative;
  }
  .headline {
    --font-size: 1.25rem;
    --line-height: 1.5rem;
    padding: 0 0.575rem;
    width: 100%;
  }
  .subtitle {
    --font-size: 0.938rem;
    --line-height: 1.375rem;
    padding: 0 0.575rem;
    margin-top: 0.75rem;
  }
  .button {
    font-size: 0.938rem;
    line-height: 1.063rem;
    height: 2.625rem;
    margin-top: 1.3rem;
    min-width: 100%;
  }
  .image {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .container {
    margin-top: 12.4rem;
  }
  .card {
    background: var(--gradient-pink-purple-default);
    border-radius: 1rem;
    overflow: hidden;
    padding: 2.5rem;
    padding-bottom: 3rem;
    position: relative;
  }
  .headline {
    width: 100%;
  }
  .subtitle {
    margin-top: 0.75rem;
  }
  .button {
    height: 3.375rem;
    margin-top: 1.875rem;
    width: 15.375rem;
  }
  .image {
    display: none;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
    margin-top: 6.25rem;
  }
  .card {
    background: var(--gradient-pink-purple-default);
    border-radius: 1rem;
    overflow: hidden;
    padding: 2.5rem 3.75rem;
    padding-bottom: 3rem;
    position: relative;
  }
  .headline {
    width: 50%;
  }
  .subtitle {
    margin-top: 0.75rem;
  }
  .button {
    height: 3.375rem;
    margin-top: 1.875rem;
    width: 15.375rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
  .image {
    height: auto !important;
    margin-left: auto;
    margin-right: 4.375rem;
    margin-top: 2rem;
    object-fit: fill;
    width: auto !important;
  }
}
