.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  border: none;
  border-radius: var(--button-border-radius, 0.625rem);

  outline: none;
  background-color: var(--button-background-color, transparent);
  color: var(--color-brand-white);

  transition: background-color 0.2s ease;
}

.button:hover {
  cursor: pointer;
  background-color: var(
    --button-background-color,
    var(--color-illustrations-black-primary)
  );
  opacity: 0.5;
  transition: background-color 0.2s ease;
}

.button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 1px var(--color-brand-white);
}

.button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button:disabled:focus-visible {
  outline: none;
  box-shadow: none;
}

.button[data-variant="square"] {
  background-color: var(--button-background-color, var(--color-brand-gray-bg));
  color: var(--color-brand-white);
}

.button[data-variant="circle"] {
  background-color: var(--button-background-color, var(--color-brand-gray-bg));
  color: var(--color-brand-white);
  border-radius: 100%;
}

.button[data-size="sm"] {
  width: var(--button-width, 2.25rem);
  height: var(--button-height, 2.25rem);
}

.button[data-size="md"] {
  width: var(--button-width, 2.625rem);
  height: var(--button-height, 2.625rem);
}

.button[data-size="sm"] .icon {
  width: var(--button-icon-width, 0.9rem);
  height: var(--button-icon-height, 0.9rem);
}
