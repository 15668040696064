.item {
  font-size: 0.938rem;
  line-height: 0.938rem;
  color: var(--color-bread-crumbs-text);
}

.separator {
  font-size: 0.938rem;
  line-height: 0.938rem;
  color: var(--color-bread-crumbs-separator);
}

.current-page {
  font-size: 0.938rem;
  line-height: 0.938rem;
  color: var(--color-bread-crumbs-text-active);
}
