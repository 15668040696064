@media only screen and (max-width: 767px) {
  .container {
    margin-top: 6.25rem;
  }
  .cta {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.7rem;
    row-gap: 0.75rem;
    width: 100%;
  }
  .headline {
    width: 100%;
  }
  .subtitle {
    width: 100%;
  }
  .cases {
    grid-template-columns: 1fr;
    margin-left: 0;
    margin-right: 0;
    margin-top: 3.125rem;
    overflow: hidden;
    row-gap: 3.125rem;
  }
  .embla__container {
    backface-visibility: hidden;
    column-gap: 1.875rem;
    display: grid;
    margin-left: 0;
    margin-right: 0;
    row-gap: 1.875rem;
    touch-action: pan-y pinch-zoom;
  }
  .card {
    margin: auto;
    background-color: var(--color-illustrations-black-bg);
    border-radius: 1rem;
    height: auto;
    min-height: 12.25rem;
    min-width: 20.25rem;
    width: 100%;
    overflow: hidden;
    padding: 1.25rem;
    position: relative;
  }
  .card__image {
    height: auto !important;
    height: 4.5rem !important;
    max-width: 100% !important;
    position: absolute !important;
    top: 1.7rem !important;
    left: 1.25rem !important;
    right: 0 !important;
    width: auto !important;
  }
  .card__description {
    display: none;
  }
  .card__content {
    position: absolute;
    top: 6rem;
  }
  .card__headline {
    margin-top: 1rem;
  }
  .card__list {
    display: none;
  }
  .header {
    display: block;
  }
  .navigation {
    display: none;
  }
  .navigation-button {
    appearance: none;
    --button-background-color: var(--button-slider-default-background);
    height: 3rem;
    touch-action: manipulation;
    width: 3rem;
    --icon-height: 1rem;
    --icon-width: 1rem;
  }
  .button {
    font-size: 0.938rem;
    height: 2.625rem;
    line-height: 1.063rem;
  }
  .card__more-link {
    align-items: center;
    --link-color: var(--color-text-accent);
    display: flex;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 1.25rem;

    --icon-height: 0.8rem;
    --icon-width: 0.8rem;
  }
  .card__more-icon {
    margin-left: 0.5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .container {
    margin-top: 12.5rem;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.55rem;
    row-gap: 1.5rem;
  }
  .headline {
    width: 50%;
  }
  .subtitle {
    width: 80%;
  }
  .cases {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    margin-top: 3.5rem;
    overflow: hidden;
  }
  .embla__container {
    backface-visibility: hidden;
    column-gap: 1.875rem;
    display: flex;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    touch-action: pan-y pinch-zoom;
  }
  .card {
    background-color: var(--color-illustrations-black-bg);
    border-radius: 1rem;
    height: 22.5rem;
    min-width: 20.625rem;
    overflow: hidden;
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .card__more-link {
    display: none;
    --link-color: var(--color-text-accent);
    margin-top: auto;
  }
  .card__image {
    height: auto !important;
    height: 6.25rem !important;
    max-width: 100% !important;
    position: absolute !important;
    top: 1.7rem !important;
    left: 1.5rem !important;
    right: 1.5rem !important;
    width: auto !important;
  }
  .card__description {
    margin-top: 1.25rem;
    padding-right: 1.75rem;
  }
  .card__content {
    position: absolute;
    top: 7.5rem;
  }
  .card__headline {
    margin-top: 1.5rem;
  }
  .card__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 1.25rem;
    row-gap: 0.375rem;
  }
  .header {
    display: block;
  }
  .navigation {
    column-gap: 1rem;
    display: flex;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-top: 2rem;
  }
  .navigation-button {
    --button-background-color: var(--button-slider-default-background);
    --button-height: 3rem;
    --button-width: 3rem;
    border-radius: 0.5rem;
    --icon-height: 0.7rem;
    --icon-width: 0.7rem;
    appearance: none;
    touch-action: manipulation;
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    margin-top: 11.75rem;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
  }
  .headline {
    width: 50%;
  }
  .subtitle {
    margin-top: 0.75rem;
    margin-right: -1rem;
    width: 50%;
  }
  .cases {
    margin-top: 3.75rem;
  }
  .embla__container {
    backface-visibility: hidden;
    column-gap: 1.875rem;
    display: flex;
    touch-action: pan-y pinch-zoom;
  }
  .card {
    background-color: var(--color-illustrations-black-bg);
    border-radius: 1rem;
    height: 21rem;
    min-width: 21.875rem;
    max-width: 21.875rem;
    overflow: hidden;
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .card__more-link {
    display: none;
    --link-color: var(--color-text-accent);
  }
  .card__image {
    height: auto !important;
    height: 6.25rem !important;
    max-width: 100% !important;
    position: absolute !important;
    top: 1.7rem !important;
    left: 1.5rem !important;
    right: 1.5rem !important;
    width: auto !important;
  }
  .card__description {
    margin-top: 1.25rem;
    padding-right: 1.25rem;
  }
  .card__content {
    position: absolute;
    top: 7.5rem;
  }
  .card__headline {
    margin-top: 1.25rem;
  }
  .card__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 1.25rem;
    row-gap: 0.375rem;
  }
  .header {
    display: none;
  }
  .navigation {
    display: none;
  }
}

.card__headline__link {
  display: inline-block;
  width: fit-content;
}
