@media only screen and (max-width: 767px) {
  .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 6.3rem;
  }
  .card {
    background: var(--gradient-pink-purple-form);
    border-radius: 0.625rem;
    overflow: hidden;
    padding: 1.375rem 0.75rem;
    padding-bottom: 2rem;
    position: relative;
  }
  .headline {
    text-align: left;
    width: 100%;
  }
  .subtitle {
    --font-size: 0.938rem;
    --line-height: 1.375rem;
    margin-top: 0.875rem;
  }
  .button {
    font-size: 0.938rem;
    height: 2.625rem;
    line-height: 1.063rem;
    margin-top: 1.125rem;
    width: 100%;
  }
  .image {
    height: auto !important;
    margin-left: auto;
    margin-right: 4.375rem;
    margin-top: 3.3rem;
    object-fit: fill;
    width: auto !important;
  }
  .form {
    margin-top: 1.75rem;
    width: 100%;
    --background: transparent;
    --border: white;
    --check-color: white;
    --foreground: transparent;
  }
  .terms {
    cursor: pointer;
    align-items: center;
    column-gap: 0.75rem;
    display: flex;
    font-size: 0.875rem;
    justify-content: flex-start;
    line-height: 1rem;
    margin-top: 0.5rem;
    width: 100%;
  }
  .input {
    background-color: var(--input-default-backround);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-text-main);
    font-size: 0.938rem;
    height: 3.375rem;
    line-height: 1.063;
    min-width: 100%;
    padding: 1rem;
    width: 100%;
  }
  .input:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .input:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .input:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary);
    outline-offset: 0.125rem;
  }
  .input::placeholder {
    color: var(--color-text-secondary);
  }
  .textarea {
    background-color: var(--input-default-backround);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-text-main);
    font-size: 0.938rem;
    height: 3rem;
    line-height: 1.063;
    margin-top: 1.25rem;
    padding: 1rem;
    width: 100%;
    resize: none;
  }
  .textarea:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .textarea:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .textarea:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary);
    outline-offset: 0.125rem;
  }
  .textarea::placeholder {
    color: var(--color-text-secondary);
  }
  .form__contact {
    column-gap: 1.25rem;
    column-gap: 1.25rem;
    display: flex;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    width: 100%;
  }
  .label {
    color: var(--color-text-secondary);
    font-weight: 400;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .form__item {
    padding: 0 !important;
    position: relative;
    position: relative;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 6.2rem;
  }
  .card {
    background: var(--gradient-pink-purple-form);
    border-radius: 2.5rem;
    overflow: hidden;
    padding: 3rem 5.938rem;
    padding-bottom: 3.75rem;
    position: relative;
  }
  .headline {
    text-align: center;
    width: 100%;
  }
  .subtitle {
    --font-size: 1rem;
    margin-top: 1.25rem;
  }
  .button {
    height: 3.375rem;
    margin-top: 1.5rem;
    width: 100%;
  }
  .image {
    height: auto !important;
    margin-left: auto;
    margin-right: 4.375rem;
    margin-top: 3.3rem;
    object-fit: fill;
    width: auto !important;
  }
  .form {
    margin-top: 2.5rem;
    width: 31.25rem;
    --background: transparent;
    --border: white;
    --check-color: white;
    --foreground: transparent;
  }
  .terms {
    cursor: pointer;
    align-items: center;
    column-gap: 0.75rem;
    display: flex;
    font-size: 0.875rem;
    justify-content: flex-start;
    line-height: 1rem;
    margin-top: 0.5rem;
    width: 100%;
  }
  .input {
    background-color: var(--input-default-backround);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-text-main);
    font-size: 1rem;
    height: 3.5rem;
    line-height: 1rem;
    min-width: 100%;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }
  .input:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .input:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .input:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary);
    outline-offset: 0.125rem;
  }
  .input::placeholder {
    color: var(--color-text-secondary);
  }
  .textarea {
    background-color: var(--input-default-backround);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-text-main);
    font-size: 1rem;
    height: 7.313rem;
    line-height: 1rem;
    padding: 1.25rem 1rem;
    resize: none;
    width: 100%;
  }
  .textarea:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .textarea:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .textarea:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary);
    outline-offset: 0.125rem;
  }
  .textarea::placeholder {
    color: var(--color-text-secondary);
  }
  .form__contact {
    column-gap: 1.25rem;
    display: flex;
    width: 100%;
  }
  .label {
    color: var(--color-text-secondary);
    font-weight: 400;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .form__item {
    position: relative;
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 12.5rem;
  }
  .card {
    background: var(--gradient-pink-purple-form);
    border-radius: 2.5rem;
    overflow: hidden;
    padding: 4.25rem 3.75rem;
    padding-bottom: 5rem;
    position: relative;
  }
  .headline {
    width: 45%;
  }
  .subtitle {
    --font-size: 1rem;
    --line-height: 1.5rem;
    margin-top: 2rem;
  }
  .button {
    height: 3.375rem;
    margin-top: 1.5rem;
    width: 100%;
  }
  .form {
    --background: transparent;
    --border: white;
    --check-color: white;
    --foreground: transparent;
  }
  .terms {
    cursor: pointer;
    align-items: center;
    column-gap: 0.75rem;
    display: flex;
    font-size: 0.875rem;
    justify-content: flex-start;
    line-height: 1rem;
    margin-top: 0.5rem;
    width: 100%;
  }
  .input {
    background-color: var(--input-default-backround);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-text-main);
    font-size: 1rem;
    height: 3.5rem;
    line-height: 1rem;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    margin-top: 0.25rem;
    width: 13.25rem;
  }
  .input:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .input:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .input:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary);
    outline-offset: 0.125rem;
  }
  .input::placeholder {
    color: var(--color-text-secondary);
  }
  .textarea {
    background-color: var(--input-default-backround);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-text-main);
    font-size: 1rem;
    height: 7.313rem;
    line-height: 1rem;
    padding: 1.25rem 1rem;
    padding-bottom: 1rem;
    resize: none;
    width: 100%;
  }
  .textarea:invalid {
    --c-border: var(--color-invalid);
    --c-outline: var(--color-invalid);
    --c-text: var(--color-invalid);
  }
  .textarea:is(:disabled, :read-only) {
    opacity: 0.5;
  }
  .textarea:is(:focus, :focus-visible) {
    outline: 0.063rem solid var(--color-text-secondary);
    outline-offset: 0.125rem;
  }
  .textarea::placeholder {
    color: var(--color-text-secondary);
  }
  .form__contact {
    column-gap: 1.25rem;
    display: flex;
  }
  .label {
    color: var(--color-text-secondary);
    font-weight: 400;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .form__item {
    position: relative;
  }
}

.errorMessage {
  display: none;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
.files_container.error {
  color: var(--color-text-error) !important;
}
.fileList {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.fileList button {
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: space-between;
  --icon-width: 0.7rem;
  --icon-height: 0.7rem;
  padding: 0.5rem;
  background-color: var(--file-background);
  outline: none;
  border: none;
  border-radius: 0.375rem;
  color: var(--color-text-secondary-2);
}
.fileList button svg {
  color: var(--color-illustrations-dark-gray);
}
.error + .errorMessage {
  display: block;
  color: var(--color-text-error);
}
.terms.error {
  color: var(--color-text-error);
  --border: var(--color-text-error);
  --check-color: var(--color-text-error);
}
